import React, { useState, useContext, useMemo } from 'react'
import * as Page from '../../components/Page'
import {
  ICreatePageProps,
  IForm,
  TCreateValidationSchemaOptions,
} from './types'
import { Translater } from '../../config'
import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import { useValidation } from '../../hooks'
import * as Components from './components'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { config } from '../../config'
import { useHttp } from '../../hooks'
import { useHistory } from 'react-router-dom'

const CreatePage: React.FC<ICreatePageProps> = () => {
  const { language } = useContext(Contexts.LanguageContext)
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()
  const [rest, setRest] = useState<any>({})
  const [form, setForm] = useState<IForm>({
    nameUA: '',
    nameRU: '',
    photo: null,
  })

  const history = useHistory()
  const [isClicked, setClick] = useState(false)

  const schema = useMemo<TCreateValidationSchemaOptions>(
    () => ({
      nameUA: {
        condition: form.nameUA.length > 2,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${
                      Translater.OptionsForProductsPage.error.name[
                        language.slug
                      ]
                    } UA`,
      },
      nameRU: {
        condition: form.nameRU.length > 2,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${
                      Translater.OptionsForProductsPage.error.name[
                        language.slug
                      ]
                    } RU`,
      },
      photo: {
        condition: form.photo != null,
        error: `${
          Translater.OptionsForProductsPage.error.photo[language.slug]
        }`,
      },
    }),
    [form]
  )

  const { validation, errors } = useValidation(schema)

  const Events = {
    CreateHandler: async () => {
      try {
        setClick(true)
        await validation()

        const data = new FormData()

        data.append(
          'name',
          JSON.stringify([
            { lang: 'ru', string: form.nameRU },
            { lang: 'ua', string: form.nameUA },
          ])
        )
        form.photo && data.append('photo', form.photo)

        await request(`${config.API}/ingredients`, 'POST', data, {
          Authorization: `Bearer ${token as string}`,
        })

        history.goBack()
      } catch (e) {
        toogleIsAlertOpen(true)
      }
    },
  }

  return (
    <Page.Wrapper
      title={Translater.OptionsForProductsPage.CreatePage[language.slug]}
    >
      <Page.Header
        backButtonTitle={
          Translater.OptionsForProductsPage.CreatePage[language.slug]
        }
        backButtonLink='/ingredients'
      />
      <Buttons.Container
        disabled={false}
        createHandler={Events.CreateHandler}
      />

      <Components.ConfigBlock
        form={form}
        setForm={setForm}
        isClicked={isClicked}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='error'>
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default CreatePage
