import _ from 'lodash'
import { useContext } from 'react'
import * as Contexts from '../contexts'

type UseTranslate = { lang: string; string: string }[]

export const useTranslate = () => {
  const { language } = useContext(Contexts.LanguageContext)

  const translate = (translations?: UseTranslate) => {
    if (_.isEmpty(translations)) {
      return ''
    }

    return translations?.find((item) => item?.lang == language?.slug)?.string
  }

  return { translate }
}
