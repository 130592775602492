import React, { Dispatch, useContext, useEffect, useState } from 'react'
import { FC } from 'react'

import * as Page from '../../../../components/Page'
import * as Contexts from '../../../../contexts'

import { IProductForm } from '../types'
import { DefaultButton } from '../../../../components/Buttons'
import { Translater, config } from '../../../../config'
import { useHttp, useTranslate } from '../../../../hooks'
import { IIngredients, IProduct } from '../../../../types/items'

type AddIngredients = {
  form: IProductForm
  setForm: Dispatch<IProductForm>
}

export const AddIngredients: FC<AddIngredients> = ({ form, setForm }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { request } = useHttp()
  const { translate } = useTranslate()

  const [ingredients, setIngredients] = useState<IIngredients[] | null>(null)
  const [showValue, setShowValue] = useState(false)
  const [ingredient, setIngredient] = useState<any>([])
  const [searchSelAds, setSearchSelAds] = useState<string>('')

  const getIngredients = async () => {
    try {
      const Authorization = `Bearer ${token as string}`

      const response = await request(
        `${config.API}/ingredients?perPage=${100}&page=${1}&sortBy=updatedAt`,
        'GET',
        null,
        { Authorization }
      )

      if (response) {
        setIngredients(response)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const deleteIngredient = async (id: string) => {
    if (id) {
      try {
        setForm &&
          setForm({
            ...form,
            ingredients: form.ingredients?.filter((i) => i._id !== id),
          })

        const Authorization = `Bearer ${token as string}`
        await request(
          //@ts-ignore
          `${config.API}/ingredients/product/${form._id}/${id}`,
          'DELETE',
          null,
          {
            Authorization,
          }
        )
      } catch (error) {}
    } else {
      setForm &&
        setForm({
          ...form,
          ingredients: form.ingredients?.filter((i) => i != null),
        })
    }
  }
  const addIngredients = () => {
    setShowValue(!showValue)
  }
  const addIngredient = () => {
    if (Object.keys(ingredient).length) {
      const ingredients = form.ingredients && [
        ...form.ingredients.filter((i) => i !== ingredient._id),
        ingredient,
      ]

      setForm && setForm({ ...form, ingredients })
    }
  }
  const adsSelectHandler = (ad: any) => {
    setIngredient(ad)
  }

  const getIngredient = async () => {
    try {
      const response = await request(
        //@ts-ignore
        `https://production.kioto.kitg.com.ua/api/products/${form?._id}/ingredients`,
        'GET',
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      )

      setForm({
        ...form,
        ingredients: response?.ingredients?.map((item: any) => ({
          ...item,
          name: translate(item?.name),
        })),
      })
    } catch (e) {}
  }

  useEffect(() => {
    getIngredients()
    getIngredient()
  }, [])

  return (
    <>
      {showValue && ingredients ? (
        <div>
          <div className='adds'>
            <Page.SearchSelect
              placeholder={
                Translater.Placeholders.searchAddition[language.slug]
              }
              data={ingredients?.map((item) => ({
                ...item,
                name: item.name[language.slug === 'ru' ? 0 : 1]?.string,
              }))}
              searchField='name'
              setSwitch={adsSelectHandler}
              value={searchSelAds}
              setValue={setSearchSelAds}
            />
            <DefaultButton
              title={Translater.Buttons.add[language.slug]}
              buttonHandler={addIngredient}
            />
          </div>
        </div>
      ) : null}

      {form.ingredients?.map((i, idx) => (
        <Page.Item
          data={i}
          fields={['name']}
          idx={idx}
          key={idx}
          deleteHandler={deleteIngredient}
          deleteField='_id'
        />
      ))}

      {access.products?.change && (
        <DefaultButton
          title={Translater.Buttons.addSet[language.slug]}
          buttonHandler={addIngredients}
        />
      )}
    </>
  )
}
