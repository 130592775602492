import React, { Dispatch, useContext, useEffect, useState } from "react";
import { FC } from "react";

import * as Page from "../../../../components/Page";
import * as Contexts from "../../../../contexts";

import { IProductForm } from "../types";
import { DefaultButton } from "../../../../components/Buttons";
import { Translater, config } from "../../../../config";
import { useHttp, useTranslate } from "../../../../hooks";
import { IProduct } from "../../../../types/items";

type AddIngredients = {
  form: IProductForm;
  setForm: Dispatch<IProductForm>;
};

export const AddProducts: FC<AddIngredients> = ({ form, setForm }) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { request } = useHttp();
  const { translate } = useTranslate();

  const [products, setProducts] = useState<IProduct[] | null>(null);
  const [showValue, setShowValue] = useState(false);
  const [product, setProduct] = useState<any>([]);
  const [searchSelAds, setSearchSelAds] = useState<string>("");
  const [name, setName] = useState("");

  const getProducts = async () => {
    try {
      const Authorization = `Bearer ${token as string}`;

      const response = await request(
        `${
          config.API
        }/products?perPage=${100}&page=${1}&sortBy=updatedAt&name=${name}`,
        "GET",
        null,
        { Authorization }
      );

      if (response) {
        setProducts(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deleteIngredient = async (id: string) => {
    if (id) {
      try {
        setForm &&
          setForm({
            ...form,
            components: form.components?.filter((i) => i._id !== id),
          });

        await request(
          //@ts-ignore
          `${config.API}/products/${form?._id}/components/${id}`,
          "DELETE",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
      } catch (error) {}
    } else {
      setForm &&
        setForm({
          ...form,
          components: form.components?.filter((i) => i != null),
        });
    }
  };
  const addIngredients = () => {
    setShowValue(!showValue);
  };
  const addIngredient = () => {
    if (Object.keys(product).length) {
      const components = form.components && [
        ...form.components.filter((i) => i._id !== product._id),
        product,
      ];

      setForm && setForm({ ...form, components });
    }
  };
  const adsSelectHandler = (ad: any) => {
    setName("");
    setProduct(ad);
  };

  const getComponents = async () => {
    try {
      const response = await request(
        //@ts-ignore
        `https://production.kioto.kitg.com.ua/api/products/${form?._id}/components`,
        "GET",
        null,
        {
          Authorization: `Bearer ${token as string}`,
        }
      );

      setForm({
        ...form,
        components: response?.components.map((item: any) => ({
          ...item,
          name: translate(item.name),
        })),
      });
    } catch (e) {}
  };

  useEffect(() => {
    getProducts();
  }, [name]);

  useEffect(() => {
    getComponents();
  }, []);

  return (
    <>
      {showValue && products ? (
        <div>
          <div className="adds">
            <Page.SearchSelect
              inputValue={name}
              onInputChange={(v) => setName(v)}
              placeholder={
                Translater.Placeholders.searchAddition[language.slug]
              }
              data={products?.map((item) => ({
                ...item,
                //@ts-ignore
                name: item.name[language.slug === "ru" ? 0 : 1]?.string,
              }))}
              searchField="name"
              setSwitch={adsSelectHandler}
              value={searchSelAds}
              setValue={setSearchSelAds}
            />
            <DefaultButton
              title={Translater.Buttons.add[language.slug]}
              buttonHandler={addIngredient}
            />
          </div>
        </div>
      ) : null}

      {form.components?.map((i, idx) => (
        <Page.Item
          data={i}
          fields={["name"]}
          idx={idx}
          key={idx}
          deleteHandler={deleteIngredient}
          deleteField="_id"
        />
      ))}

      {access.products?.change && (
        <DefaultButton
          title={Translater.Buttons.addSet[language.slug]}
          buttonHandler={addIngredients}
        />
      )}
    </>
  );
};
