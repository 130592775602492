import React, { useContext, useState, useEffect } from 'react'
import * as Page from '../../components/Page'
import { Translater } from '../../config'
import * as Contexts from '../../contexts'
import * as Components from './components'
import { useHttp } from '../../hooks'
import { config } from '../../config'
import { IIngredients, IPagination } from '../../types/items'
import { useHistory } from 'react-router-dom'
import { IAdditions } from '../../types/items'

const Ingredients: React.FC = () => {
  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const history = useHistory()

  const [ingredients, setIngredients] = useState<IIngredients[] | null>(null)
  const [loader, setLoader] = useState<boolean>(true)

  const { request, totalCount } = useHttp()

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  })
  const [filter, setFilter] = useState<any>({
    sortBy: 'updatedAt',
    sortOrder: 'desc',
  })
  const [searchValue, setSearchValue] = useState<string>('')

  const Callbacks = {
    Fetch: async () => {
      try {
        const Authorization = `Bearer ${token as string}`

        const response = await request(
          `${config.API}/ingredients?perPage=${pagination.perPage}&page=${pagination.page}&sortBy=${filter.sortBy}`,
          'GET',
          null,
          { Authorization }
        )

        if (response) {
          setIngredients(response)
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoader(false)
      }
    },
    Delete: async (addition: any) => {
      try {
        await request(`${config.API}/ingredients/${addition}`, 'DELETE', null, {
          Authorization: `Bearer ${token as string}`,
        })
      } catch (e) {
        console.log(e)
      }
      Callbacks.Fetch()
    },
  }

  const Events = {
    clearFilterHandler: () => {
      Events.reloadHandler()
    },
    editHandler: (id: string) => {
      history.push(`/ingredients/${id}`)
    },
    deleteHandler: (addition: string) => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) {
        Callbacks.Delete(addition)
      }
    },
    reloadHandler: () => {
      Callbacks.Fetch()
    },
    selectHandler: (e: any) => {
      setPagination({ ...pagination, perPage: +e.target.value })
    },
    sortHandler: (name: any) => {
      let sortOrder: 'asc' | 'desc'
      if (filter.sortOrder === 'asc') sortOrder = 'desc'
      else sortOrder = 'asc'
      setFilter({
        sortBy: name,
        sortOrder,
      })

      setPagination({ ...pagination, page: 1 })
    },

    paginateHandler: (page: number) => {
      setPagination({ ...pagination, page })
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination.page, pagination.perPage])

  useEffect(() => {
    setPagination({ ...pagination, totalCount })
  }, [totalCount])

  useEffect(() => {
    Callbacks.Fetch()
  }, [pagination.page, pagination.perPage, filter.sortBy, filter.sortOrder])

  return (
    <Page.Wrapper title={Translater.Ingredients.title[language.slug]}>
      <Page.Header
        title={Translater.Ingredients.title[language.slug]}
        linkTitle={Translater.Buttons.create[language.slug]}
        enableButton={access.restaurants?.change}
        linkTo='/ingredients/create'
      />

      <div className='filter-container'>
        <Page.SortBar
          perPage={pagination.perPage}
          selectHandler={Events.selectHandler}
        />
      </div>

      {ingredients && (
        <Page.WaitWrapper
          data={ingredients.length > 0}
          loader={loader}
          no={Translater.OptionsForProductsPage.no[language.slug]}
        >
          <Components.Table
            sortHandler={Events.sortHandler}
            page={pagination.page}
            Events={Events}
            data={ingredients}
          />
        </Page.WaitWrapper>
      )}

      <Page.Footer>
        <Page.Pagination
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          linkTo='/options-for-products'
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  )
}

export default Ingredients
