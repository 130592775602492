import React, { useContext } from 'react'
import * as Table from '../../../../components/Table'
import { TrackProps } from './types'
import * as Contexts from '../../../../contexts'
import { config, Translater } from '../../../../config'
import { useTranslate } from '../../../../hooks'

const Track: React.FC<TrackProps> = ({ data, Events, order }) => {
  const { access } = useContext(Contexts.UserContext)
  const { translate } = useTranslate()

  console.log(data.name)

  return (
    <Table.Track type='ingredients'>
      <Table.Cell text={order + ''} />

      <Table.Cell type='image' imageURL={`${config.imageProxy}${data.photo}`} />

      <Table.Cell text={translate(data?.name)} />

      <Table.Cell
        type='control'
        editButton={access.stocks?.change}
        deleteButton={access.stocks?.delete}
        editHandler={() => Events.editHandler(data._id)}
        deleteHandler={() => Events.deleteHandler(data._id)}
      />
    </Table.Track>
  )
}

export default Track
