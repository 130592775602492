import React, { useContext, useEffect, useState } from 'react'
import { IConfigBlockProps } from './types'
import * as Form from '../../../components/Form'
import { Translater } from '../../../config'
import * as Contexts from '../../../contexts'
import { config } from '../../../config'
import { useHttp } from '../../../hooks'
import { IRestaurant } from '../../../types/items'
import * as Page from '../../../components/Page'
import { useParams } from 'react-router-dom'
import { IForm } from '../types'

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  isClicked,
  setForm,
}) => {
  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)
  const { request } = useHttp()
  const { id }: any = useParams()

  const Events = {
    inputHandler: (e: any) => {
      const name = e.target.name
      const value = e.target.value
      //@ts-ignore
      setForm((state: any) => ({ ...state, [name]: value }))
    },
    photoHandler: (e: any) => {
      if (e.target?.files[0] !== null) {
        setForm({ ...form, photo: e.target?.files[0] })
      }
    },
  }

  const Callbacks = {
    getIngredients: async () => {
      try {
        const Authorization = `Bearer ${token as string}`

        if (!id) return

        const ingredients = await request(
          `${config.API}/ingredients/${id}`,
          'GET',
          null,
          {
            Authorization,
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
  }

  useEffect(() => {
    Callbacks.getIngredients()
  }, [])

  return (
    <div>
      <Form.LabelField
        fontSize='small'
        label={`${Translater.Placeholders.options.title[language.slug]} UA`}
      >
        <Form.Input
          type='text'
          name='nameUA'
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} UA`}
          isValid={isClicked ? form.nameUA.length > 2 : true}
          value={form.nameUA}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize='small'
        label={`${Translater.Placeholders.options.title[language.slug]} RU`}
      >
        <Form.Input
          type='text'
          name='nameRU'
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} RU`}
          isValid={isClicked ? form.nameUA.length > 2 : true}
          value={form.nameRU}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.Placeholders.options.photo[language.slug]}
      >
        {form.photo &&
          (typeof form.photo === 'string' || form.photo instanceof String) && (
            <img
              alt='Category'
              src={`${config.imageProxy}${form.photo}`}
              style={{ objectFit: 'cover', width: 350, height: 250 }}
            />
          )}
        <Form.Input
          type='file'
          name='photo'
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.photo != null : true}
          inputHandler={Events.photoHandler}
        />
      </Form.LabelField>
    </div>
  )
}

export default ConfigBlock
