import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as Page from '../../components/Page'
import * as Contexts from '../../contexts'
import * as Buttons from '../../components/Buttons'
import { Translater } from '../../config'
import { TCreateValidationSchemaOptions, IForm } from './types'
import * as Components from './components'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useValidation } from '../../hooks'
import { useParams } from 'react-router-dom'
import { useHttp } from '../../hooks'
import { config } from '../../config'
import { useHistory } from 'react-router-dom'

const DetailPage: React.FC = () => {
  const { id }: any = useParams()
  const { request } = useHttp()
  const history = useHistory()

  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)
  const [form, setForm] = useState<IForm>({
    nameUA: '',
    nameRU: '',
    photo: null,
  })
  const [isClicked, setClick] = useState(false)
  const [loader, toogleLoader] = useState<boolean>(true)

  const schema = useMemo<TCreateValidationSchemaOptions>(
    () => ({
      nameUA: {
        condition: form?.nameUA?.length > 2,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${
                      Translater.OptionsForProductsPage.error.name[
                        language.slug
                      ]
                    } UA`,
      },
      nameRU: {
        condition: form?.nameRU?.length > 2,
        error: `${Translater.OptionsForProductsPage.error.length[language.slug]}
                    ${
                      Translater.OptionsForProductsPage.error.name[
                        language.slug
                      ]
                    } RU`,
      },
      photo: {
        condition: form.photo != null,
        error: `${
          Translater.OptionsForProductsPage.error.photo[language.slug]
        }`,
      },
    }),
    [form, language]
  )

  const { validation, errors } = useValidation(schema)

  const Callbacks = {
    GetIngredientsById: async () => {
      toogleLoader(true)

      try {
        const Authorization = `Bearer ${token as string}`

        const ingredients = await request(
          `${config.API}/ingredients/${id}`,
          'GET',
          null,
          {
            Authorization,
          }
        )

        if (ingredients?._id) {
          setForm({
            nameUA: ingredients?.name[1]?.string,
            nameRU: ingredients?.name[0]?.string,
            photo: ingredients?.photo,
            _id: ingredients?._id,
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        toogleLoader(false)
      }
    },

    Save: async () => {
      try {
        setClick(true)
        await validation()

        const data = new FormData()

        data.append(
          'name',
          JSON.stringify([
            { lang: 'ru', string: form.nameRU },
            { lang: 'ua', string: form.nameUA },
          ])
        )

        form.photo && data.append('photo', form.photo)

        await request(`${config.API}/ingredients/${form?._id}`, 'PATCH', data, {
          Authorization: `Bearer ${token as string}`,
        })

        await Events.reloadHandler()
        history.push('/ingredients')
      } catch (e) {
        toogleIsAlertOpen(errors.length > 0)
      }
    },

    Delete: async () => {
      try {
        await request(
          `${config.API}/ingredients/${form?._id}`,
          'DELETE',
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
      } catch (e) {
      } finally {
        history.push(`/ingredients`)
      }
    },
  }

  const Events = {
    reloadHandler: () => {
      Callbacks.GetIngredientsById()
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) {
        Callbacks.Delete()
      }
    },
  }

  useEffect(() => {
    Callbacks.GetIngredientsById()
  }, [id])

  console.log(form)

  return (
    <Page.Wrapper
      title={Translater.OptionsForProductsPage.DetailPage[language.slug]}
    >
      <Page.Header
        backButtonTitle={
          Translater.OptionsForProductsPage.DetailPage[language.slug]
        }
        backButtonLink='/ingredients'
      />

      <Page.WaitWrapper loader={loader} data={form} withoutNoLength>
        <Buttons.Container
          disabled={false}
          saveHandler={access.stocks?.change ? Callbacks.Save : false}
          deleteHandler={access.stocks?.delete ? Events.deleteHandler : false}
        />

        <Components.ConfigBlock
          form={form}
          setForm={setForm}
          isClicked={isClicked}
        />

        <Snackbar
          open={isAlertOpen}
          autoHideDuration={10000}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={() => toogleIsAlertOpen(false)}
        >
          <Alert severity='error'>
            {errors.map((error) => (
              <p key={`error-item-${error}`}>- {error}</p>
            ))}
          </Alert>
        </Snackbar>
      </Page.WaitWrapper>
    </Page.Wrapper>
  )
}

export default DetailPage
