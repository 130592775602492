import React, { useContext, useEffect, useState } from 'react'

import * as Contexts from '../../../../contexts'
import * as Form from '../../../../components/Form'
import { config, Translater } from '../../../../config'
import { IProductCardProps } from './types'
import { useHttp, useTranslate } from '../../../../hooks'
import { IAdditions } from '../../../../types/items'
import _ from 'lodash'
import async from 'react-select/async'

const ProductCard: React.FC<IProductCardProps> = ({ data }) => {
  const { language } = useContext(Contexts.LanguageContext)
  const { token } = useContext(Contexts.AuthContext)
  const { translate } = useTranslate()
  const { request } = useHttp()
  const [additions, setAdditions] = useState<IAdditions[]>([])

  const getAddition = async (id: string) => {
    try {
      const Authorization = `Bearer ${token as string}`

      const addition = await request(
        `${config.API}/addition/${id}`,
        'GET',
        null,
        {
          Authorization,
        }
      )

      return addition
    } catch (e) {
      return null
    }
  }

  const additionSum = additions?.reduce(
    (prev, current) => prev + current?.price,
    0
  )

  useEffect(() => {
    if (_.isEmpty(data)) {
      return
    }

    // Map each _id to a promise of getAddition call
    const promises = data?.product?.additions?.map((_id) => {
      // @ts-ignore
      return getAddition(_id)
    })

    // Wait for all the promises to resolve and then set the additions
    Promise.all(promises).then((additions) => {
      // @ts-ignore
      setAdditions(additions)
    })
  }, [data])

  return (
    <div className='block-container border'>
      <h2 className='block-title'>
        {
          //@ts-ignore
          translate(data?.product?.name)
        }
      </h2>

      <Form.LabelField
        label={Translater.TableTitles.photo[language.slug]}
        fontSize='small'
      >
        <img
          alt='Product'
          src={`${config.imageProxy}${data?.product?.photo}`}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.title[language.slug]}
        text={
          //@ts-ignore
          translate(data?.product?.name)
        }
      />
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.description[language.slug]}
        text={
          data?.product?.description
            ? //@ts-ignore
              translate(data?.product?.description)
            : '-'
        }
      />
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.options[language.slug]}
        text={additions
          .map(
            (addition) =>
              `${
                //@ts-ignore
                translate(addition?.name)
              }: x${addition?.price} пор. `
          )
          .join(', ')}
      />
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.amount[language.slug]}
        text={`${data.count}`}
      />
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.price[language.slug]}
        text={`${data.price} грн`}
      />
      <Form.LabelField
        fontSize='small'
        label={Translater.TableTitles.sum[language.slug]}
        text={`${data.price + additionSum} грн`}
      />
    </div>
  )
}

export default ProductCard
