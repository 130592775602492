import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
  useLayoutEffect,
} from "react";

import * as Components from "./components";
import * as Contexts from "../../contexts";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";
import * as Buttons from "../../components/Buttons";
import * as Feedback from "../../components/Feedback";

import { useHttp } from "../../hooks";
import { config, Translater } from "../../config";
import { useParams, useHistory } from "react-router-dom";
import { IClient, TClientForm } from "../../types/items";
import { useValidation } from "../../hooks";
import { TDetailValidationSchema } from "./types";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import TableHistoryBonuses from "./components/Table/TableHistoryBonuses";

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const history = useHistory();
  const { id } = useParams() as any;
  const { loading, request } = useHttp();
  const [primary, setPrimary] = useState<IClient | null>(null);

  const [bonusesHistory, setBonusesHistory] = useState<any>();

  const [form, setForm] = useState<TClientForm>({
    name: "",
    dateOfBirth: "",
  });

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const schema = useMemo<TDetailValidationSchema>(
    () => ({
      name: {
        condition: !!form.name,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      dateOfBirth: {
        condition: !!new Date(form.dateOfBirth!),
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.dateOfBirth[language.slug]}`,
      },
    }),
    [form, language]
  );
  const { errors, validation } = useValidation(schema);

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      setForm({ ...form, [name]: value });
    },
    saveHandler: async () => {
      try {
        await validation();

        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
        console.log("createHandler => e", e);
      }
    },
  };

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IClient = await request(
          `${config.API}/users/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        if (response) {
          setPrimary(response);
        }
      } catch (e) {
        console.log(e);
      }
    },
    Save: async () => {
      try {
        const response = await request(
          `${config.API}/users/${primary?._id}`,
          "POST",
          { ...form },
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) history.push("/users");
      } catch (e) {
        console.log(e);
      }
    },
    GetBonusesHistory: async () => {
      try {
        const response = await request(
          `${config.API}/bonuseshistory/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        setBonusesHistory(response);
      } catch (e) {}
    },
  };

  useLayoutEffect(() => {
    Callbacks.Fetch();
  }, []);

  useEffect(() => {
    if (primary)
      setForm({
        ...form,
        name: primary.name,
        dateOfBirth: primary.dateOfBirth,
      });
  }, [primary]);

  useEffect(() => {
    Callbacks.GetBonusesHistory();
  }, []);

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper footer title={primary.name}>
      <Page.Header
        backButtonTitle={Translater.UsersDetailPage.title[language.slug]}
        backButtonLink="/users"
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.users?.change ? Events.saveHandler : false}
      />

      <Components.ConfigBlock
        form={form}
        client={primary}
        // isValid={isValid}
        inputHandler={Events.inputHandler}
      />

      {/* {<Components.DetailBlock data={primary} />} */}

      <Form.DateBlock
        updated={primary.updatedAt as string}
        created={primary.createdAt as string}
      />

      {/* {<Feedback.Block userID={id} link={`/users/${id}`} />} */}

      {/* <TableHistoryBonuses sortHandler={null as any} items={null as any} /> */}
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default DetailPage;
