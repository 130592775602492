import React, {
  useState,
  useContext,
  ChangeEvent,
  useMemo,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import * as Contexts from "../../contexts";
import * as Buttons from "../../components/Buttons";
import * as Page from "../../components/Page";
import * as Components from "./components";

import { useHttp } from "../../hooks";
import { config, Translater } from "../../config";

import { IOptions } from "../../types/items";
import {
  TMutliLangTitles,
  TMutliLangDescriptions,
  TPossibleMultiLangTitle,
  TPossibleMultiLangDescriptions,
} from "../../types/multiLang";
import { TCreateValidationSchema } from "./types";
import { useValidation } from "../../hooks";
import { IProductForm } from "./components/types";
import { nanoid } from "nanoid";
import { IOneCategory } from "../../types";

const CreatePage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);
  const { multiLang } = useContext(Contexts.ConfigContext);

  const { loading, request } = useHttp();
  const history = useHistory();
  const [categoryOptions, setCategoryOptions] = useState<IOneCategory[]>([]);

  const [form, setForm] = useState<IProductForm>({
    nameUA: "",
    nameRU: "",
    photo: [],
    parent: "",
    hidden: false,
    category: { label: "", value: "" },
    subCategory: null,
    restaurant: [],
    descriptionUA: "",
    descriptionRU: "",
    options: [],
    weight: "",
    novelty: false,
    additions: [],
    variations: [],
    ingredients: [],
    components: [],
    isVariated: "false",
    isSet: false,
    price: 0,
  });

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  const [isClicked, setClicked] = useState(false);

  const schema = useMemo<TCreateValidationSchema>(
    () => ({
      nameRU: {
        condition: form.nameRU.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.title[language.slug]} RU`,
      },
      nameUA: {
        condition: form.nameUA.length >= 2,
        error: `
        ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.title[language.slug]} UA`,
      },
      weight: {
        condition:
          (parseInt(form.weight) >= 0 &&
            (form.isVariated === "false" || !!!form.isVariated)) ||
          (!!form.isVariated && form.isVariated !== "false"),
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.weight[language.slug]}`,
      },
      price: {
        condition:
          (form.price > 0 && form.isVariated === "false") ||
          (form.isVariated && form.isVariated === "true") ||
          form.isVariated,
        error: ` ${Translater.ErrorLength[language.slug]}:
        ${Translater.TableTitles.price[language.slug]}`,
      },
      category: {
        condition: !!form.category.value,
        error: `${Translater.ErrorField[language.slug]}:
        ${Translater.TableTitles.categoriesList[language.slug]}
        `,
      },
      restaurant: {
        condition: form.restaurant.length === 1 || form.restaurant.length > 1,
        error: `${Translater.ErrorField[language.slug]}: ${
          Translater.ErrorRestaurants[language.slug]
        }`,
      },
      photo: {
        condition: !!form.photo,
        error: Translater.ErrorPhoto[language.slug],
      },
      variations: {
        condition:
          (form.isVariated &&
            form.isVariated !== "false" &&
            form.variations.length) ||
          form.isVariated === "false" ||
          !form.isVariated,
        error: Translater.ErrorVariations[language.slug],
      },
    }),
    [form, Translater, language]
  );

  const { errors, validation } = useValidation(schema);

  const [optionValue, setOptionValue] = useState<any>({
    option: "",
    photo_option: "",
    price_option: "0",
    additions: "",
  });

  const [options, setOptions] = useState<IOptions[]>([]);

  const [similar, setSimilar] = useState<string[]>([]);

  const [multiLangTitles, setMultiLangTitles] = useState<TMutliLangTitles>({
    "title[EN]": "",
    "title[RU]": "",
    "title[UA]": "",
  });

  const [multiLangDescriptions, setMultiLangDescriptions] =
    useState<TMutliLangDescriptions>({
      "description[EN]": "",
      "description[RU]": "",
      "description[UA]": "",
    });

  const Events = {
    inputOptionHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const type = e.target.type;
      const value = e.target.value;

      //@ts-ignore
      if (type === "file")
        //@ts-ignore
        setOptionValue({ ...optionValue, photo_option: e.target.files[0] });
      else {
        setOptionValue({ ...optionValue, [name]: value });
      }
    },
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const type = e.target.type;
      const value = e.target.value;
      const files = e.target.files;

      if (type === "file" && files && files.length) {
        const newFiles = Array.from(files).map((file) => ({
          file,
          id: nanoid(),
        }));
        setForm((prevForm) => ({
          ...prevForm,
          newPhoto: [...(prevForm.newPhoto || []), ...newFiles],
        }));
      } else if (type === "number") {
        if (+value >= 0) setForm({ ...form, [name]: value });
      } else if (name.split("[")[1]) {
        setMultiLangTitles({
          ...multiLangTitles,
          [name]: value,
        });
      } else setForm({ ...form, [name]: value });
    },
    textareaHandler: (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name.split("[")[1]) {
        setMultiLangDescriptions({
          ...multiLangDescriptions,
          [e.target.name]: e.target.value,
        });
      } else setForm({ ...form, [e.target.name]: e.target.value });
    },
    buttonOptionHandler: () => {
      setOptions([...options, optionValue]);
      setOptionValue({
        option: "",
        photo_option: "",
        price_option: "0",
        additions: "",
      });
    },
    setHandler: (arr: string[]) => {
      setSimilar(arr);
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      const name = e.target.name;

      if (name == "isSet") {
        setForm({
          ...form,
          [e.target.name]: e.target.value == "true" ? true : false,
        });
        return;
      }

      setForm({ ...form, [e.target.name]: e.target.value });
    },
    setCategory: (category: { label: string; value: string }) => {
      setForm({ ...form, category });
    },
    setRestaurant: (restaurant: string[]) => {
      setForm({ ...form, restaurant });
    },
    saveHandler: async () => {
      try {
        setClicked(true);
        await validation();

        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
        console.log(e);
      }
    },
    loadCategoriesOptions: async () => {
      try {
        const response: IOneCategory[] = await request(
          `${config.API}/categories?perPage=50`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        setCategoryOptions(response);
      } catch (e) {
        console.log(e);
      }
    },
  };

  const Callbacks = {
    Save: async () => {
      try {
        const data = new FormData();

        if (multiLang) {
          for (let key in multiLangTitles) {
            data.append(key, multiLangTitles[key as TPossibleMultiLangTitle]);
          }
        } else
          data.append(
            "name",
            JSON.stringify([
              { lang: "ru", string: form.nameRU },
              { lang: "ua", string: form.nameUA },
            ])
          );

        if (multiLang) {
          for (let key in multiLangDescriptions) {
            data.append(
              key,
              multiLangDescriptions[key as TPossibleMultiLangDescriptions]
            );
          }
        } else
          data.append(
            "description",
            JSON.stringify([
              { lang: "ru", string: form.descriptionRU },
              { lang: "ua", string: form.descriptionUA },
            ])
          );

        if (form.newPhoto && form.newPhoto.length > 0) {
          (form.newPhoto as { file: File; id: string }[]).map((item) => {
            data.append("files", item.file);
          });
        }
        data.append("isVariated", form.isVariated + "");
        data.append("isSet", form.isSet + "");

        data.append("price", form.price + "");
        data.append("hidden", form.hidden + "");
        data.append("novelty", form.novelty + "");
        data.append(
          "category",
          form.subCategory?.value ? form.subCategory.value : form.category.value
        );
        for (let rest of form.restaurant) {
          //@ts-ignore
          data.append("restaurant[]", rest);
        }
        if (form.parent) data.append("parent", form.parent);
        data.append("weight", form.weight);
        form.additions.forEach((item) => data.append("additions[]", item._id));

        for (let varData of form.variations) {
          data.append("variations[]", JSON.stringify(varData));
        }

        const response = await request(`${config.API}/products`, "POST", data, {
          Authorization: `Bearer ${token as string}`,
        });

        if (form.isSet) {
          const ids = form?.components?.map((item) => {
            return item._id as string;
          });

          await request(
            `${config.API}/products/${response?._id}/components`,
            "POST",
            { components: ids },
            {
              Authorization: `Bearer ${token as string}`,
            }
          );
        } else {
          const ids = form?.ingredients?.map((item) => {
            return item._id as string;
          });

          await request(
            `${config.API}/ingredients/product/${response?._id}`,
            "POST",
            { ingredients: ids },
            {
              Authorization: `Bearer ${token as string}`,
            }
          );
        }
        history.push("/products");
      } catch (e) {}
    },
  };
  useEffect(() => {
    Events.loadCategoriesOptions();
  }, []);
  return (
    <Page.Wrapper title={Translater.ProductsCreatePage.title[language.slug]}>
      <Page.Header
        backButtonTitle={Translater.ProductsCreatePage.title[language.slug]}
        backButtonLink="/products"
      />

      <Buttons.Container
        disabled={loading}
        createHandler={access.products?.change ? Events.saveHandler : false}
      />

      <Components.ConfigBlock
        categories={categoryOptions}
        form={form}
        setForm={setForm}
        optionValue={optionValue}
        options={options}
        multiLangTitles={multiLangTitles}
        multiLangDescriptions={multiLangDescriptions}
        setCategory={Events.setCategory}
        setRestaurant={Events.setRestaurant}
        inputHandler={Events.inputHandler}
        inputOptionHandler={Events.inputOptionHandler}
        buttonOptionHandler={Events.buttonOptionHandler}
        selectHandler={Events.selectHandler}
        textareaHandler={Events.textareaHandler}
        isClicked={isClicked}
      />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error, i) => (
            <p key={error + i}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default CreatePage;
