import React, { FC, Fragment, useContext } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import uk from 'date-fns/locale/uk'
import 'react-datepicker/dist/react-datepicker.css'

import { LanguageContext, UserContext } from '../../../contexts'

import { TContactsBlockProps } from './types'
import { Input, LabelField } from '../../../components/Form'
import { Translater } from '../../../config'
import './restaurants.scss'

registerLocale('uk', uk) // register it with the name you want

const ContactsBlock: FC<TContactsBlockProps> = ({
  form,
  isValid,
  setValue,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  isClicked,
  value,
  setCoordinates,
  inputHandler,
}) => {
  const { language } = useContext(LanguageContext)

  const { ref }: any = usePlacesWidget({
    language: 'ua',
    apiKey: 'AIzaSyDXDFN_m23B9T1oukgdRvffZE72iO_9SRE',
    options: {
      types: ['address'],
    },
    onPlaceSelected: (place) => {
      setValue && setValue(place.formatted_address)
      setCoordinates &&
        setCoordinates({
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng(),
        })
    },
  })

  return (
    <Fragment>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.email[language.slug]}
      >
        <Input
          name='email'
          value={form.email}
          isValid={isClicked ? !!form.email : true}
          placeholder={Translater.Placeholders.minThree[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.phone[language.slug]}
      >
        <Input
          name='phone'
          value={form.phone}
          isValid={isClicked ? !!form.phone : true}
          placeholder={Translater.Placeholders.minThree[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.facebook[language.slug]}
      >
        <Input
          name='facebook'
          value={form.facebook}
          placeholder={Translater.Placeholders.tapsLinks[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.instagram[language.slug]}
      >
        <Input
          name='instagram'
          value={form.instagram}
          placeholder={Translater.Placeholders.tapsLinks[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.telegram[language.slug]}
      >
        <Input
          name='telegram'
          value={form.telegram}
          placeholder={Translater.Placeholders.tapsLinks[language.slug]}
          inputHandler={inputHandler}
        />
      </LabelField>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.addressLabel[language.slug]}
      >
        <input
          name='value'
          className={`google-autocomplete`}
          ref={ref}
          defaultValue={value}
          value={value}
          placeholder={Translater.Placeholders.enterAddress[language.slug]}
          disabled={false}
          onChange={(e) => setValue && setValue(e.target.value)}
        />
      </LabelField>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.startTime[language.slug]}
      >
        <DatePicker
          selected={new Date(startDate ?? '') ?? new Date()}
          onChange={(date: any) => setStartDate && setStartDate(date)}
          showTimeSelect
          className={`date-picker`}
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption='Time'
          name='startDate'
          locale='uk'
          dateFormat='HH:mm'
        />
      </LabelField>
      <LabelField
        fontSize='small'
        label={Translater.TableTitles.endTime[language.slug]}
      >
        <DatePicker
          selected={new Date(endDate ?? '') ?? new Date()}
          onChange={(date: any) => setEndDate && setEndDate(date)}
          showTimeSelect
          showTimeSelectOnly
          name='endDate'
          className={`date-picker`}
          timeIntervals={15}
          timeCaption='Time'
          locale='uk'
          dateFormat='HH:mm'
        />
      </LabelField>
    </Fragment>
  )
}

export default ContactsBlock
