import React, { Fragment, useState, useContext, useEffect } from "react";

import * as Contexts from "../../../contexts";
import * as Form from "../../../components/Form";

import { config, Translater } from "../../../config";
import { useHttp } from "../../../hooks/http.hook";

import { IStock, IOption } from "../../../types/items";
import { IConfigBlockProps } from "./types";
import { IRestaurant } from "../../../types/items";
import { Editor } from "../../../components/Editor/Editor";
import moment from "moment";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  multiLangValues,
  setParentID,
  inputHandler,
  selectHandler,
  editorHandler,
  setForm,
  isClicked,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { multiLang } = useContext(Contexts.ConfigContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();

  const [restaurantOptions, setRestaurantOptions] = useState<IRestaurant[]>([]);

  const Events = {
    loadOptions: async () => {
      try {
        const response: IStock[] = await request(
          `${config.API}/stocks/all`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        const formatted: IOption[] = [];
        if (response.length) {
          for (const item of response) {
            const option = {
              value: item._id as string,
              label: item.name as string,
            };
            formatted.push(option);
          }
        }
        return formatted;
      } catch (e) {
        console.log(e);
      }
    },
    optionHandler: (option: any) => {
      setParentID(option.value);
    },
    loadRestaurantOptions: async () => {
      try {
        const response: IRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );
        setRestaurantOptions(response);
      } catch (e) {}
    },
  };

  useEffect(() => {
    Events.loadRestaurantOptions();
  }, []);

  console.log(form, "form");

  return (
    <Fragment>
      {console.log("work")}
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.title[language.slug]}
      >
        <Form.Input
          name="title"
          type="text"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.title.length >= 2 : true}
          value={form.title}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        <Form.Input
          type="file"
          name="photo"
          isValid={isClicked ? !!form.photo : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      {data?.photo &&
        (typeof data?.photo === "string" || data?.photo instanceof String) && (
          <img
            alt="Category"
            src={`${config.imageProxy}${data?.photo}`}
            style={{ objectFit: "cover", width: 350, height: 250 }}
          />
        )}
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.publicationDate[language.slug]}
      >
        <p className="label-text">
          {data?.updatedAt
            ? moment(data?.updatedAt).format("DD.MM.YYYY")
            : moment(data?.createdAt).format("DD.MM.YYYY")}
        </p>
      </Form.LabelField>

      {restaurantOptions ? (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.restaurants[language.slug]}
        >
          <Form.Dropdown
            isOption={false}
            disabledValue={
              Translater.Placeholders.chooseRestaurant2[language.slug]
            }
            items={restaurantOptions}
            item={form.restaurants[0]}
            name="restaurants"
            changeHandler={selectHandler}
          />
        </Form.LabelField>
      ) : null}

      <Editor initialValue={form.body} onEditorChange={editorHandler} />
    </Fragment>
  );
};

export default ConfigBlock;
