import React, { useContext } from "react";
import * as Table from "../../../../components/Table";
import { TrackProps } from "./types";
import * as Contexts from "../../../../contexts";
import { config, Translater } from "../../../../config";

const Track: React.FC<TrackProps> = ({ data, Events, order }) => {
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  return (
    <Table.Track type="options">
      <Table.Cell text={order + ""} />

      <Table.Cell type="image" imageURL={`${config.imageProxy}${data.photo}`} />

      <Table.Cell text={data?.name[language.slug === "ru" ? 0 : 1].string} />

      <Table.Cell text={data?.price + " грн"} />

      <Table.Cell text={data.restaurant} />

      <Table.Cell
        text={
          data.weight
            ? data.weight + " г "
            : Translater.TableTitles.noInfo[language.slug]
        }
      />

      <Table.Cell
        type="control"
        editButton={access.stocks?.change}
        deleteButton={access.stocks?.delete}
        editHandler={() => Events.editHandler(data._id)}
        deleteHandler={() => Events.deleteHandler(data._id)}
      />
    </Table.Track>
  );
};

export default Track;
