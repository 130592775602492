import React, { useContext } from "react";

import * as Contexts from "../../contexts";
import * as Errors from "../Errors";
import * as Modal from "../Modal";
import * as Page from ".";

import Router from "../../router/router";

const Collector: React.FC = () => {
  const { isOnline } = useContext(Contexts.OnlineContext);
  const { isReady: userReady } = useContext(Contexts.UserContext);
  const { isReady: configReady } = useContext(Contexts.ConfigContext);
  const { isReady: authReady, isAuthenticated } = useContext(
    Contexts.AuthContext
  );

  if (!isOnline) {
    return (
      <Errors.Wrapper>
        <Errors.Network />
      </Errors.Wrapper>
    );
  }

  if (!userReady || !configReady || !authReady) {
    return <Page.Preloader />;
  }

  return (
    <div className="app">
      {isAuthenticated && <Page.Sidebar />}
      {isAuthenticated && <Modal.Modal />}

      <Router />
    </div>
  );
};

export default Collector;
