import React, { useState, useEffect, useContext, ChangeEvent } from "react";

import * as Page from "../../components/Page";
import * as Contexts from "../../contexts";
import * as Components from "./components";

import { useHttp } from "../../hooks";

import { config, Translater } from "../../config";

import { IPagination, IFilter, TSortBy } from "../../types/items";
import { IOneOrder } from "../../types";

const IndexPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { loading, totalCount, request } = useHttp();

  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    perPage: 10,
    totalCount: 0,
  });
  const [filter, setFilter] = useState<IFilter>({
    sortBy: "updatedAt",
    sortOrder: "desc",
  });

  const [primary, setPrimary] = useState<IOneOrder[]>([]);

  const Events = {
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setPagination({ ...pagination, page: 1, perPage: +e.target.value });
    },
    sortHandler: (name: TSortBy) => {
      let sortOrder: "asc" | "desc";
      if (filter.sortOrder === "asc") sortOrder = "desc";
      else sortOrder = "asc";
      setFilter({
        sortBy: name,
        sortOrder,
      });

      setPagination({ ...pagination, page: 1 });
    },
    paginateHandler: (index: number) => {
      setPagination({ ...pagination, page: index });
    },
    reloadHandler: () => {
      Callbacks.Fetch();
    },
  };

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IOneOrder[] = await request(
          `${config.API}/orders/history/?page=${pagination.page}&perPage=${pagination.perPage}&sortOrder=${filter.sortOrder}&sortBy=${filter.sortBy}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          },
          true
        );

        if (response) {
          setPrimary(response);
        }
      } catch (e) {}
    },
  };

  useEffect(() => {
    setPagination({ ...pagination, totalCount });
  }, [totalCount]);

  useEffect(() => {
    Callbacks.Fetch();
  }, [filter.sortBy, filter.sortOrder, pagination.page, pagination.perPage]);

  if (loading || !primary) return <Page.Preloader />;

  return (
    <Page.Wrapper footer title={Translater.ArchivePage.title[language.slug]}>
      <Page.Header title={Translater.ArchivePage.title[language.slug]} />

      <Page.SortBar
        perPage={pagination.perPage}
        filter={filter}
        selectHandler={Events.selectHandler}
      />

      <Components.Table
        items={primary}
        sortHandler={Events.sortHandler}
        reloadHandler={Events.reloadHandler}
      />

      <Page.Footer>
        <Page.Pagination
          linkTo="/archive"
          pagination={pagination}
          paginateHandler={Events.paginateHandler}
          setPagination={setPagination}
        />
      </Page.Footer>
    </Page.Wrapper>
  );
};

export default IndexPage;
