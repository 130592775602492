import React, { useContext, useState, useEffect } from 'react'

import * as Contexts from '../../contexts'
import * as Page from '../../components/Page'
import * as Components from './components'

import { useHttp } from '../../hooks/http.hook'
import { useParams } from 'react-router-dom'
import { config, Translater } from '../../config'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { IOneOrder } from '../../types'
import _ from 'lodash'

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext)
  const { language } = useContext(Contexts.LanguageContext)

  const { id } = useParams() as any
  const { loading, request } = useHttp()

  const [primary, setPrimary] = useState<IOneOrder | null>(null)
  const [loader, toogleLoader] = useState<boolean>(true)

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false)

  const Events = {
    reloadHandler: () => {
      toogleIsAlertOpen(true)
      Callbacks.Fetch()
    },
  }

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IOneOrder = await request(
          `${config.API}/orders/${id}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        )
        if (response) {
          setPrimary(response)
        }
      } catch (e) {
      } finally {
        toogleLoader(false)
      }
    },
  }

  useEffect(() => {
    Callbacks.Fetch()
  }, [])

  useEffect(() => {
    Callbacks.Fetch()
  }, [isAlertOpen])

  if (loading || !primary) return <Page.Preloader />

  return (
    <Page.Wrapper
      title={`${Translater.OrdersDetailPage.title[language.slug]} №${
        primary.id
      }`}
    >
      <Page.WaitWrapper loader={loader} data={primary} withoutNoLength>
        <Page.Header
          backButtonTitle={Translater.OrdersDetailPage.title[language.slug]}
          backButtonLink='/orders'
        />

        <Components.DetailBlock
          data={primary}
          reloadHandler={Events.reloadHandler}
        />

        {!_.isEmpty(primary?.items) && (
          <Components.ProductsList items={primary?.items} />
        )}
      </Page.WaitWrapper>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={1000}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity='success'>
          {Translater.TableTitles.changedOrderStatus[language.slug]}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  )
}

export default DetailPage
