import React, { useContext, useState, useEffect } from "react";

import * as Contexts from "../../contexts";

import { NavLink } from "react-router-dom";
import { Translater } from "../../config";
import { INavLink } from "../Page/types";
import home from "../../assets/icons/home.svg";
import order from "../../assets/icons/order.svg";
import categories from "../../assets/icons/categories.svg";
import restaurant from "../../assets/icons/restaurant.svg";
import product from "../../assets/icons/products.svg";
import options from "../../assets/icons/options.svg";
import news from "../../assets/icons/news.svg";
import sale from "../../assets/icons/sale.svg";
import consumer from "../../assets/icons/consumer.svg";
import staff from "../../assets/icons/staff.svg";
import role from "../../assets/icons/role.svg";
import archive from "../../assets/icons/archive.svg";
import notification from "../../assets/icons/notifications.svg";
import bookingList from "../../assets/icons/bookingList.svg";
import booking from "../../assets/icons/booking.svg";
import fererals from "../../assets/icons/referal.svg";
import delivery from "../../assets/icons/delivery.svg";
import settings from "../../assets/icons/settings.svg";
import feedback from "../../assets/icons/feedback.png";
import birthday from "../../assets/icons/birthday.svg";

const CustomNavLink: React.FC<INavLink> = ({ slug, linkTo, clickHandler }) => {
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const [localAccess, setLocalAccess] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [image, setImage] = useState<any>(home);

  useEffect(() => {
    switch (slug) {
      case "home":
        if (access.settings && access.settings?.view) {
          setLocalAccess(true);
          setImage(home);
          setTitle(Translater.Sidebar.home[language.slug]);
        }
        break;
      case "orders":
        if (access.orders?.view && access.orders?.view) {
          setLocalAccess(true);
          setImage(order);
          setTitle(Translater.Sidebar.orders[language.slug]);
        }
        break;
      case "categories":
        if (access.categories?.view && access.categories?.view) {
          setLocalAccess(true);
          setImage(categories);
          setTitle(Translater.Sidebar.categories[language.slug]);
        }
        break;
      case "restaurants":
        if (access.restaurants?.view && access.restaurants?.view) {
          setLocalAccess(true);
          setImage(restaurant);
          setTitle(Translater.Sidebar.restaurants[language.slug]);
        }
        break;
      case "products":
        if (access.products?.view && access.products?.view) {
          setLocalAccess(true);
          setImage(product);
          setTitle(Translater.Sidebar.products[language.slug]);
        }
        break;
      case "options":
        if (access.products?.view && access.products?.view) {
          setLocalAccess(true);
          setImage(options);
          setTitle(Translater.Sidebar.options[language.slug]);
        }
        break;
      case "ingredients":
        if (access.products?.view && access.products?.view) {
          setLocalAccess(true);
          setImage(options);
          setTitle(Translater.Sidebar.ingredients[language.slug]);
        }
        break;
      /* case 'feedback':
        if (
          ( any)?.feedback
          && access.products?.children?.feedback?.view
          && access.products?.children?.feedback?.view !== 'false') {
          setLocalAccess(true)
          setImage(feedback)
          setTitle(Translater.Sidebar.feedback[language.slug])
        }
        break */
      // case 'sales':
      //   if (false) { //!fix view from backend //TODO create
      //     setLocalAccess(true)
      //     setImage(sale)
      //     setTitle(Translater.Sidebar.sales[language.slug])
      //   }
      //   break
      case "news":
        if (access?.settings && access?.settings?.view) {
          setLocalAccess(true);
          setImage(news);
          setTitle(Translater.Sidebar.news[language.slug]);
        }
        break;
      case "stocks":
        if (access.stocks?.view && access.stocks?.view) {
          setLocalAccess(true);
          setImage(sale);
          setTitle(Translater.Sidebar.stocks[language.slug]);
        }
        break;
      case "users":
        if (access.users?.view && access.users?.view) {
          setLocalAccess(true);
          setImage(consumer);
          setTitle(Translater.Sidebar.users[language.slug]);
        }
        break;
        // case "usersBirthdays":
        //   if (access.users?.view && access.users?.view) {
        //     setLocalAccess(true);
        //     setImage(birthday);
        //     setTitle(Translater.Sidebar.birthdays[language.slug]);
        //   }
        break;
      case "staff":
        if (access.staff?.view && access.staff?.view) {
          setLocalAccess(true);
          setImage(staff);
          setTitle(Translater.Sidebar.staff[language.slug]);
        }
        break;
      case "roles":
        if (access.roles && access.roles?.view) {
          setLocalAccess(true);
          setImage(role);
          setTitle(Translater.Sidebar.roles[language.slug]);
        }
        break;
      case "history":
        if (access.ordersHistory && access.ordersHistory?.view) {
          setLocalAccess(true);
          setImage(archive);
          setTitle(Translater.Sidebar.history[language.slug]);
        }
        break;
      case "notifications":
        setLocalAccess(true);
        setImage(notification);
        setTitle(Translater.Sidebar.notifications[language.slug]);
        break;
      case "booking":
        setLocalAccess(true);
        setImage(bookingList);
        setTitle(Translater.Sidebar.booking[language.slug]);
        break;
      case "bookingDetails":
        setLocalAccess(true);
        setImage(booking);
        setTitle(Translater.Sidebar.bookingDetails[language.slug]);
        break;
      case "referals":
        setLocalAccess(true);
        setImage(fererals);
        setTitle(Translater.Sidebar.referals[language.slug]);
        break;
      case "delivery":
        setLocalAccess(true);
        setImage(delivery);
        setTitle(Translater.Sidebar.delivery[language.slug]);
        break;
      case "settings":
        setLocalAccess(true);
        setImage(settings);
        setTitle(Translater.Sidebar.settings[language.slug]);
        break;
      default:
        setLocalAccess(false);
    }
  }, [language]);

  if (!localAccess) return null;

  return (
    <NavLink className="sidebar-link" to={linkTo} onClick={clickHandler}>
      <img alt={slug} src={image} />
      {title}
    </NavLink>
  );
};

export default CustomNavLink;
