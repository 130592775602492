import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useMemo,
} from "react";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import * as Components from "./components";
import * as Contexts from "../../contexts";
import * as Buttons from "../../components/Buttons";
import * as Page from "../../components/Page";
import * as Form from "../../components/Form";

import { TCoordinates } from "./types";
import { IRestaurant } from "../../types/items";
import { useParams, useHistory } from "react-router-dom";
import { config, Translater } from "../../config";
import { useHttp } from "../../hooks";
import { useValidation } from "../../hooks";

import { TRoleRest } from "./components/types";

const DetailPage: React.FC = () => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { language } = useContext(Contexts.LanguageContext);

  const history = useHistory();
  const { id } = useParams() as any;
  const { loading, request } = useHttp();

  const [primary, setPrimary] = useState<IRestaurant | null>(null);
  const [headerPhoto, setHeaderPhoto] = useState<File | null | any>(null);
  const [photo, setPhoto] = useState<File | null>(null);
  const [roleGlobal, setRoleGlobal] = useState<TRoleRest>();
  const [roleStr, setRoleStr] = useState<string | null>("");
  const [startDate, setStartDate] = useState<Date | string>(new Date());
  const [endDate, setEndDate] = useState<Date | string>(new Date());
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [form, setForm] = useState<any>({
    city: "",
    login: "",
    title: "",
    password: "",
    email: "",
    phone: "",
    facebook: "",
    instagram: "",
    telegram: "",
    headerColor: "",
  });
  const [value, setValue] = useState<string>("");
  const [coordinates, setCoordinates] = useState<TCoordinates>({
    lat: "0",
    lon: "0",
  });

  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  const schema = useMemo<any>(
    () => ({
      title: {
        condition: form.title.length >= 3,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.title[language.slug]}`,
      },
      photo: {
        condition: true,
        error: `
        ${Translater.ErrorPhoto[language.slug]}:
        ${Translater.TableTitles.phone[language.slug]}`,
      },
      phone: {
        condition: !!form.phone,
        error: `
          ${Translater.ErrorLength[language.slug]}:
          ${Translater.TableTitles.phone[language.slug]}`,
      },
      // email: {
      //   condition: !!form.email,
      //   error: `
      //     ${Translater.ErrorLength[language.slug]}:
      //     ${Translater.TableTitles.email[language.slug]}`,
      // },
      value: {
        condition: !!value,
        error: `
          ${Translater.ErrorAddress[language.slug]}:
          ${Translater.TableTitles.address[language.slug]}`,
      },
      startDate: {
        condition:
          moment(startDate).format("HH:mm") <= moment(endDate).format("HH:mm"),
        error: `${Translater.ErrorTimeStart[language.slug]}`,
      },
      endDate: {
        condition:
          moment(startDate).format("HH:mm") <= moment(endDate).format("HH:mm"),
        error: `${Translater.ErrorTimeEnd[language.slug]}`,
      },
    }),
    [form, language, value, startDate, endDate]
  );

  const { errors, validationSchema, validation } = useValidation(schema);

  const Events = {
    inputHandler: (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value;
      if (e.target.name === "photo") {
        setPhoto(e.target?.files?.length ? e.target?.files[0] : null);
      } else setForm({ ...form, [name]: value });
    },
    inputFileHandler: (e: ChangeEvent<HTMLInputElement>) => {
      setHeaderPhoto(e.target?.files?.length ? e.target?.files[0] : null);
    },
    selectHandler: (e: ChangeEvent<HTMLSelectElement>) => {
      setForm({ ...form, [e.target.name]: e.target.value });
    },
    saveHandler: async () => {
      try {
        setIsClicked(true);
        await validation();

        Callbacks.Save();
      } catch (e) {
        toogleIsAlertOpen(true);
        console.log("createHandler => e", e);
      }
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug]);
      if (answer) Callbacks.Delete();
    },
  };

  const Callbacks = {
    Fetch: async () => {
      try {
        const response: IRestaurant = await request(
          `${config.API}/restaurants/${id}`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        const {
          title,
          headerColor,
          workingTime: { end, start },
          contacts: {
            email,
            phone,
            social: { facebook, instagram, telegram },
            address: { longitude, latitude, label },
          },
        } = response;

        if (response) {
          const newForm = {
            ...form,
            title,
            email,
            value,
            phone,
            facebook,
            telegram,
            instagram,
            headerColor,
          };
          setCoordinates({
            lon: longitude,
            lat: latitude,
          });
          setStartDate(`Thu Dec 29 2022 ${start}:59 GMT+0200`);
          setEndDate(`Thu Dec 29 2022 ${end}:59 GMT+0200`);
          setValue(label);
          setForm(newForm);
          if (response) {
            setPrimary(response);
          }
        }
      } catch (e) {}
    },
    Save: async () => {
      try {
        const { title, email, phone, facebook, instagram, telegram } = form;

        const { lon, lat } = coordinates;

        const contacts: IRestaurant["contacts"] = {
          phone,
          email,
          social: {
            facebook,
            instagram,
            telegram,
          },
          address: {
            longitude: lon,
            latitude: lat,
            label: value,
          },
        };
        const data: FormData = new FormData();

        data.append("contacts", JSON.stringify(contacts));
        data.append(
          "workingTime",
          JSON.stringify({
            start: moment(startDate).format("HH:mm"),
            end: moment(endDate).format("HH:mm"),
          })
        );

        if (photo) data.append("restPhoto", photo);
        headerPhoto && data.append("headerPhoto", headerPhoto);

        data.append("title", title);

        if (form.headerColor.length > 0) {
          await request(
            `${config.API}/restaurants/${id}/headerColor`,
            "PATCH",
            { headerColor: form.headerColor },
            {
              Authorization: `Bearer ${token as string}`,
            }
          );
        }

        const response = await request(
          `${config.API}/restaurants/${id}`,
          "POST",
          data,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) history.push("/restaurants");
      } catch (e) {}
    },
    Delete: async () => {
      try {
        await request(`${config.API}/restaurants/${id}`, "DELETE", null, {
          Authorization: `Bearer ${token as string}`,
        });

        history.push("/restaurants");
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.Fetch();
  }, [id]);

  return (
    <Page.Wrapper title={primary?.title}>
      <Page.Header
        backButtonTitle={Translater.RestaurantsDetailPage.title[language.slug]}
        backButtonLink="/restaurants"
      />

      <Buttons.Container
        disabled={loading}
        saveHandler={access.restaurants?.change ? Events.saveHandler : false}
        // deleteHandler={
        //   access.restaurants?.delete ? Events.deleteHandler : false
        // }
      />

      <Components.ConfigBlock
        form={form}
        photo={photo}
        data={primary ?? undefined}
        value={value}
        headerPhoto={headerPhoto}
        role={roleGlobal}
        roleStr={roleStr as string}
        setForm={setForm as any}
        isClicked={isClicked}
        inputFileHandler={Events.inputFileHandler}
        inputHandler={Events.inputHandler}
      />

      <Components.ContactsBlock
        form={form}
        isClicked={isClicked}
        setValue={setValue}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        value={value}
        setCoordinates={setCoordinates}
        isValid={validationSchema}
        inputHandler={Events.inputHandler}
      />

      <Form.DateBlock
        updated={primary?.updatedAt as string}
        created={primary?.createdAt as string}
      />
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={10000}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={() => toogleIsAlertOpen(false)}
      >
        <Alert severity="error">
          {errors.map((error) => (
            <p key={`error-item-${error}`}>- {error}</p>
          ))}
        </Alert>
      </Snackbar>
    </Page.Wrapper>
  );
};

export default DetailPage;
