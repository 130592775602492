import React, { Fragment, useState, useContext, useEffect } from "react";

import * as Contexts from "../../../contexts";
// import * as Buttons from "../../../components/Buttons";
import * as Form from "../../../components/Form";
import * as Page from "../../../components/Page";

import { config, Translater } from "../../../config";
import { useHttp } from "../../../hooks";

import { SHiddenOptions } from "../../../components/Form/static";
// import { ICategory } from "../../../types/items";
import { IConfigBlockProps } from "./types";
import { IOneCategory, IOneRestaurant } from "../../../types";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  data,
  form,
  isValid,
  multiLangValues,
  setParentID,
  inputHandler,
  selectHandler,
  setRestaurant,
  setForm,
  isClicked,
}) => {
  const { token } = useContext(Contexts.AuthContext);
  const { access } = useContext(Contexts.UserContext);
  const { multiLang } = useContext(Contexts.ConfigContext);
  const { language } = useContext(Contexts.LanguageContext);

  const { request } = useHttp();

  const [categoryOptions, setCategoryOptions] = useState<IOneCategory[]>([]);
  const [restaurantOptions, setRestaurantOptions] = useState<any[]>([]);
  const [, setCategorySelectValue] = useState<any>({
    label: "",
    value: "",
  });

  const Events = {
    loadRestaurantOptions: async () => {
      try {
        const response: IOneRestaurant[] = await request(
          `${config.API}/restaurants`,
          "GET",
          null,
          {
            Authorization: `Bearer ${token as string}`,
          }
        );

        if (response) {
          setRestaurantOptions(response);
        }
      } catch (e) {
        console.log(e);
      }
    },
    loadCategoriesOptions: async () => {
      try {
        function createUrl() {
          const url = `${config.API}/categories?perPage=1000`;
          const arr = restaurantOptions.map((item) => {
            return item._id;
          });

          const string =
            arr && replaceAll(arr?.toString(), ",", "&restaurant[]=");
          function replaceAll(
            string: string,
            search: string,
            replace: string
          ): string {
            return string.split(search).join(replace);
          }

          return url;
        }

        if (restaurantOptions && restaurantOptions.length > 0) {
          const response: IOneCategory[] = await request(
            createUrl(),
            "GET",
            null,
            {
              Authorization: `Bearer ${token as string}`,
            }
          );
          setCategoryOptions(response);
        }
      } catch (e) {}
    },
    optionHandler: (option: any) => {
      setCategorySelectValue(option);
      setParentID(option.value);
    },
    optionRestaurantHandler: (option: any) => {
      setRestaurant && setRestaurant(option);
    },
  };

  useEffect(() => {
    Events.loadRestaurantOptions();
  }, [data?.restaurant]);

  useEffect(() => {
    Events.loadCategoriesOptions();
  }, [restaurantOptions]);

  return (
    <Fragment>
      <Form.LabelField
        fontSize="small"
        label={`${Translater.TableTitles.title[language.slug]} UA`}
      >
        <Form.Input
          name="titleUA"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? !!form.titleUA : true}
          value={form.titleUA}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize="small"
        label={`${Translater.TableTitles.title[language.slug]} RU`}
      >
        <Form.Input
          name="titleRU"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? !!form.titleRU : true}
          value={form.titleRU}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.position[language.slug]}
      >
        <Form.Input
          name="position"
          placeholder={Translater.Placeholders.enterNumber[language.slug]}
          isValid={isClicked ? isValid.position : true}
          value={form.position}
          inputHandler={inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.restaurants[language.slug]}
      >
        <Fragment>
          <Page.SearchSelect
            isMulti
            placeholder={Translater.Placeholders.searchByRests[language.slug]}
            searchField="title"
            data={restaurantOptions}
            setSwitch={(e) => {
              Events.optionRestaurantHandler(e);
            }}
            multiField="_id"
            value={data?.restaurant}
            isInvalid={
              isClicked
                ? !(
                    form.restaurant?.length! > 1 ||
                    form.restaurant?.length !== 1
                  )
                : false
            }
          />
        </Fragment>
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.hidden[language.slug]}
      >
        <Form.Select
          name="hidden"
          options={SHiddenOptions}
          value={form.hidden + ""}
          selectHandler={selectHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.categoryІelection[language.slug]}
      >
        <Form.Dropdown
          disabledValue={Translater.TableTitles.parentCategory[language.slug]}
          items={categoryOptions
            .filter((item) => item._id !== data?._id)
            .map((element) => ({
              _id: element._id,
              title: element?.title[language.slug === "ru" ? 0 : 1]?.string,
            }))}
          item={form?.parent_id}
          name="parent_id"
          isOption={true}
          changeHandler={selectHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize="small"
        label={Translater.TableTitles.photo[language.slug]}
      >
        {data?.photo ? (
          <img
            alt="Restaurant"
            src={`${config.imageProxy}${(data as any).photo}`}
            style={{ width: 350, height: 250, objectFit: "cover" }}
          />
        ) : null}
        <Form.Input
          type="file"
          name="photo"
          isValid={isClicked ? isValid.photo : true}
          inputHandler={inputHandler}
        />
      </Form.LabelField>
    </Fragment>
  );
};

export default ConfigBlock;
