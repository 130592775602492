import React from "react";
import { IHideButtonProps } from "./types";
import arrow from "../../assets/icons/arrow.png";

const HideButton: React.FC<IHideButtonProps> = ({
  isActive,
  buttonHandler,
}) => {
  return (
    <button
      className={`btn btn--hide ${isActive && "active"}`}
      onClick={buttonHandler}
    >
      <img alt="Arrow" src={arrow} />
    </button>
  );
};

export default HideButton;
