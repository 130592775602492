import React, { useContext } from "react";

import * as Contexts from "../../contexts";
import * as Buttons from "../../components/Buttons";
import * as Erorrs from "../../components/Errors";
import * as Font from "../../components/Fonts";
import forbidden from "../../assets/icons/forbidden.png";
import { Translater } from "../../config";
import { useHistory } from "react-router-dom";

const Forbidden: React.FC = () => {
  const { language } = useContext(Contexts.LanguageContext);

  const history = useHistory();

  return (
    <Erorrs.Wrapper>
      <img alt="Forbidden" src={forbidden} />

      <Font.Title title={Translater.Erros.forbidden[language.slug]} />

      <Buttons.DefaultButton
        title={Translater.Buttons.goBack[language.slug]}
        buttonHandler={history.goBack}
      />
    </Erorrs.Wrapper>
  );
};

export default Forbidden;
