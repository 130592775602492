import React, { Fragment, useContext } from 'react'

import * as Contexts from '../../../../contexts'
import * as Table from '../../../../components/Table'

import { useHistory } from 'react-router-dom'
import { useHttp } from '../../../../hooks/http.hook'
import { config, Translater } from '../../../../config'
import { ITrackProps } from './types'
import moment from 'moment'

const Track: React.FC<ITrackProps> = ({ data, reloadHandler }) => {
  const { token } = useContext(Contexts.AuthContext)
  const { access } = useContext(Contexts.UserContext)
  const { language } = useContext(Contexts.LanguageContext)
  const { actions: modalActions } = useContext(Contexts.ModalContext)

  const { request } = useHttp()
  const history = useHistory()

  const Events = {
    statusHandler: () => {
      modalActions.SHOW(data as any, 'status')
    },
    deleteHandler: () => {
      const answer = window.confirm(Translater.Alert.delete[language.slug])
      if (answer) Callbacks.Delete()
    },
    editHandler: () => {
      history.push(`/orders/${data._id}`)
    },
  }

  const Callbacks = {
    Delete: async () => {
      try {
        await request(`${config.API}/orders/${data._id}`, 'DELETE', null, {
          Authorization: `Bearer ${token as string}`,
        })

        reloadHandler()
      } catch (e) {}
    },
  }

  // console.log(data, "data");

  return (
    <Table.Track type="orders" backgroundColor={data.status}>
      <Table.Cell text={`${data.id}`} />
      <Table.Cell
        text={data.customer?.name}
        modalContent={
          <Table.ModalContent
            label={Translater.TableTitles.phone[language.slug]}
            text={
              data.customer
                ? data.customer.phone
                  ? data.customer.phone
                  : '-'
                : '-'
            }
          />
        }
      />
      <Table.Cell
        text={
          data.delivery.mode === 'courier'
            ? Translater.TableTitles.courier[language.slug]
            : Translater.TableTitles.pickup[language.slug]
        }
        modalContent={
          <Fragment>
            <Table.ModalContent
              label={Translater.TableTitles.type[language.slug]}
              text={
                data.delivery.mode === 'courier'
                  ? Translater.TableTitles.courier[language.slug]
                  : Translater.TableTitles.pickup[language.slug]
              }
            />

            {data.delivery.address ? (
              <Table.ModalContent
                label={Translater.TableTitles.address[language.slug]}
                text={data.delivery.address as any}
              />
            ) : null}
            <Table.ModalContent
              label={Translater.TableTitles.deliveryPrice[language.slug]}
              text={`${data.deliveryPrice}`}
            />
          </Fragment>
        }
      />
      <Table.Cell
        text={
          data.comment
            ? data.comment
            : Translater.TableTitles.withoutComment[language.slug]
        }
        modalContent={
          <Table.ModalContent
            text={
              data.comment
                ? data.comment
                : Translater.TableTitles.withoutComment[language.slug]
            }
          />
        }
      />
      <Table.Cell text={data?.restaurant ? data?.restaurant?.title : '-'} />
      <Table.Cell
        text={
          //@ts-ignore
          Translater.TableTitles.statusTypes?.[data?.orderStatus]?.[
            language.slug
          ]
        }
        clickHandler={Events.statusHandler}
      />
      <Table.Cell text={`${data.itemsPrice - data.paidByBonuses} грн`} />

      <Table.Cell
        text={`${
          data.paymentType === 'cash'
            ? Translater.PaymentType.cash[language.slug]
            : Translater.PaymentType.online[language.slug]
        }`}
      />

      <Table.Cell
        date={new Date(data.createdAt as string)}
        format="DD.MM.YYYY HH:mm"
        modalContent={
          <Table.ModalContent
            text={moment(data.createdAt).format('DD.MM.YYYYTHH:mm')}
          />
        }
      />

      <Table.Cell
        type="control"
        editButton={access.orders?.change}
        deleteButton={access.orders?.delete}
        editHandler={Events.editHandler}
        deleteHandler={Events.deleteHandler}
      />
    </Table.Track>
  )
}

export default Track
