import React, { Fragment, useContext } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

import * as Contexts from "../../contexts";
import * as Buttons from "../Buttons";

import { SNavLinks } from "./static";

const Sidebar: React.FC = () => {
  const { isActive, actions: sidebarActions } = useContext(
    Contexts.SidebarContext
  );

  return (
    <Fragment>
      <aside className={`sidebar ${isActive && "active"}`}>
        <NavLink className="sidebar-logo" to="/admin">
          <img alt="Logo" src={logo} />
        </NavLink>
        <nav className="sidebar-nav">
          {SNavLinks.map((link) => (
            <Buttons.NavLink
              key={link.slug}
              slug={link.slug}
              linkTo={link.linkTo}
            />
          ))}
        </nav>
      </aside>

      <Buttons.HideButton
        isActive={isActive}
        buttonHandler={sidebarActions.TOGGLE}
      />
    </Fragment>
  );
};

export default Sidebar;
