import React, { useContext, useEffect, useState } from "react";
import { IConfigBlockProps } from "./types";
import * as Form from "../../../components/Form";
import { Translater } from "../../../config";
import * as Contexts from "../../../contexts";
import { config } from "../../../config";
import { useHttp } from "../../../hooks";
import { IRestaurant } from "../../../types/items";
import * as Page from "../../../components/Page";
import { useParams } from "react-router-dom";
import { IForm } from "../types";

const ConfigBlock: React.FC<IConfigBlockProps> = ({
  form,
  isClicked,
  setForm,
  setRest,
}) => {
  const { language } = useContext(Contexts.LanguageContext);
  const { token } = useContext(Contexts.AuthContext);
  const { request } = useHttp();
  const { id }: any = useParams();

  const [rests, setRests] = useState<IRestaurant[]>([]);
  const [addition, setAddition] = useState<IForm>();
  const [restaurant, setRestaurant] = useState<IRestaurant[]>();
  const Events = {
    inputHandler: (e: any) => {
      const name = e.target.name;
      const value = e.target.value;
      //@ts-ignore
      setForm((state: any) => ({ ...state, [name]: value }));
    },

    selectHandler: (e: any) => {
      for (let rest of rests) {
        if (rest.title == e.target.value) {
          //@ts-ignore
          setForm((state: any) => ({ ...state, restaurant: rest._id }));
          setRest(rest);
        }
      }
    },
    photoHandler: (e: any) => {
      if (e.target?.files[0] !== null) {
        setForm({ ...form, photo: e.target?.files[0] });
      }
    },
  };

  console.log(rests, "rests");
  console.log(form, "form");

  const Callbacks = {
    GetRests: async () => {
      const rests = await request(`${config.API}/restaurants`, "GET", null, {
        Authorization: `Bearer ${token as string}`,
      });
      if (rests && form._id) {
        setForm && setForm({ ...form, restaurant: [rests[0]._id] });
      }
      setRests(rests);
      setRestaurant(rests);
    },
    getAddition: async () => {
      try {
        const Authorization = `Bearer ${token as string}`;
        if (!id) return;
        const addition = await request(
          `${config.API}/addition/${id}`,
          "GET",
          null,
          {
            Authorization,
          }
        );

        setAddition(addition);
      } catch (e) {
        console.log(e);
      }
    },
    getRestaurantById: async (id: string) => {
      try {
        const Authorization = `Bearer ${token as string}`;

        if (!id) return;
        const data = await request(
          `${config.API}/restaurants/${id}`,
          "GET",
          null,
          {
            Authorization,
          }
        );

        setRestaurant(data);
      } catch (e) {
        console.log(e);
      }
    },
  };

  useEffect(() => {
    Callbacks.GetRests();
  }, []);

  useEffect(() => {
    Callbacks.getAddition();
  }, []);

  return (
    <div>
      <Form.LabelField
        fontSize="small"
        label={`${Translater.Placeholders.options.title[language.slug]} UA`}
      >
        <Form.Input
          type="text"
          name="nameUA"
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} UA`}
          isValid={isClicked ? form.nameUA.length > 2 : true}
          value={form.nameUA}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>
      <Form.LabelField
        fontSize="small"
        label={`${Translater.Placeholders.options.title[language.slug]} RU`}
      >
        <Form.Input
          type="text"
          name="nameRU"
          placeholder={`${Translater.Placeholders.minTwo[language.slug]} RU`}
          isValid={isClicked ? form.nameUA.length > 2 : true}
          value={form.nameRU}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.Placeholders.options.price[language.slug]}
      >
        <Form.Input
          name="price"
          type="number"
          placeholder={"0"}
          isValid={
            isClicked ? Number(form.price) > 0 && form.price != "0" : true
          }
          value={form.price}
          inputHandler={Events.inputHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.Placeholders.options.photo[language.slug]}
      >
        {form.photo &&
          (typeof form.photo === "string" || form.photo instanceof String) && (
            <img
              alt="Category"
              src={`${config.imageProxy}${form.photo}`}
              style={{ objectFit: "cover", width: 350, height: 250 }}
            />
          )}
        <Form.Input
          type="file"
          name="photo"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.photo != null : true}
          inputHandler={Events.photoHandler}
        />
      </Form.LabelField>

      <Form.LabelField
        fontSize="small"
        label={Translater.Placeholders.g[language.slug]}
      >
        <Form.Input
          name="weight"
          type="number"
          placeholder={Translater.Placeholders.minTwo[language.slug]}
          isValid={isClicked ? form.weight > 0 : true}
          inputHandler={Events.inputHandler}
          value={form.weight}
        />
      </Form.LabelField>

      {console.log(rests, "rest")}
      {console.log(form.restaurant, "form restaturnat")}
      {console.log(restaurant, "restaturant")}

      {rests && (
        <Form.LabelField
          fontSize="small"
          label={Translater.TableTitles.restaurant[language.slug]}
        >
          <>
            <Page.SearchSelect
              isMulti
              placeholder="Выберите ресторан"
              searchField="title"
              data={rests}
              multiField="_id"
              setSwitch={(restaurant: any) =>
                setForm && setForm({ ...form, restaurant: restaurant })
              }
              value={restaurant?.filter(
                (item, index) => item._id == form.restaurant[index]
              )}
              isInvalid={
                isClicked
                  ? !(form.restaurant.length > 1 || form.restaurant.length == 1)
                  : false
              }
            />
          </>
        </Form.LabelField>
      )}
    </div>
  );
};

export default ConfigBlock;
